*{
    margin: 0;
    padding: 0;
}

.header {
    display: flex;
    background-color: #e8e7e2;
}
.header > div {
    margin: 1em;
}
.header > div:hover{
    color: #f87e04;
    user-select: none;
    cursor: pointer;
}

.oone{
    display: flex;
    /* justify-content: center; */
    margin-top: 1em;
}

.logo > img{
    width: 10em;    
}

.sousuo{
    width: 45vw;
    border: none;
    outline: none;
    border: #f87e04;
    border-width: 1px;
    border-style: solid;
    border-radius: 15px;
    display: flex;
    align-items: center;
    overflow: hidden;
    /* padding: 0em 0em 0em 2em; */
    /* height: 2em; */
}
.sousuo > img{
    width: 1em;
    height: 1em;
    margin: 0.5em;
}
.sousuo > input{
    border: none;
    outline: none;
    flex-grow: 1;
    background: none;
}
.sousuo > div{
    font-weight:900;
    background-color: #f87e04;
    padding: 0.5em 2em;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}
.sousuo > div:hover{
    background-color: #f55c00;
}

a{
    font-size: 15px;
    color: rgb(171, 175, 180);
    text-decoration: none;
}
a:hover{
    color: #f87e04;
}
.ssk > a:not(:last-child)::after{

    content: " | ";
    color: #f87e04;
}
.one{
    display: flex;
}
.fenlei{
    width: 10em;
    margin: 0.2em;
}

.f-title{
    background-color: #f87e04;
    padding: 1em;
    font-size: 15px;
    font-weight: 900;
    color: azure;
    border-radius: 10px 10px 0px 0px;
}
.fenlei > div{
    padding: 0.5em;
}
.fenlei1{
    width: 10em;
    margin: 0.2em;
    height: 2.2em;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    color: #ebdede;
}

.f-title{
    background-color: #f87e04;
    padding: 0.5em;
    font-size: 17px;
    font-weight: 900;
    color: azure;
    border-radius: 10px 10px 0px 0px;
}
.fenlei1:hover{
    height: 25em;
    box-shadow: 0 0 2em #fdfcfc;
}
.fenlei1 > div{
    padding: 0.5em;
}
.dajuhui{
    margin: 0.5em;
    display: flex;
    align-items: flex-end;
    position: relative;

}
.dajuhui > div{
    margin-left: 2em;
    font-weight:650;
    cursor: pointer;
    user-select: none;
}
.djh{
    font-size: 22px;
    color: #ea0a0a;
}
.djh1{
    font-size: 22px;
    color: #ea0a0a;
}
.dajuhui > div:not(:first-child):hover{
    color: #f87e04;

}
.bkimg {
    background-image: url("./img/2.jpg");
    width: 100%;
    height: 30vw;
    background-size: cover;
    background-position: center;
    position: relative;
}
.tupian{
    display: flex;
    justify-content: space-around;
}
.tupian img{
    width: 10em;
}

.two{
    margin: 2em;
}
.two > div{
    display: flex;
    justify-content: space-evenly;
}
.two > div > div{
    width: 30em;
    margin: 1em;
    box-shadow: 0 0 0.2em #000;
    padding: 1.5em
}

.two .biaoti{
    margin-left: 0.5em;
    font-size: 1.5em;
    font-weight: 700;
    display: flex;
    margin-bottom: 0.5em;
}
.sp{
    display: flex;
    justify-content: space-around;
}
.sp img{
    width: 10em;
}
.sp > div{
    margin-left: 1em;
    font-size: 1.2em;
}
.gengduo{
    font-size: 0.7em;
    font-weight: 400;
    color: #979494;
}
.gengduo:hover{
    user-select: none;
    cursor: pointer;
    color: #d36d07;
}
.three{
    background-color: #f4f2f1;
    height: 35em;
}
.pingdao{
    padding: 0.5em;
    display: flex;
    justify-content: center;
    color: #979494;
    font-weight: 900;
    font-size: 1.3em;
}
.calss{
    display: flex;
}
.calss > div{
    width: 50em;
    background-color: #ffffff;
    margin: 0.5em;
    border-radius: 10px;
    padding: 1em;
}
.neirong{
    display: flex;
    margin-bottom: 0.5em;
    align-items: flex-end
}
.neirong > div:first-child{
    font-size: 1.5em;
    font-weight: 700;
    margin-right: 0.3em;
}
.four{
    background-color: #f8f6f6;
    padding: 2em;
    display: flex;
    justify-content: space-between;
}
.four > div > div:nth-child(2){
    font-weight: 600;

}
.four > div > div:nth-child(2) img{
    width: 1.5em;
    margin-right: 0.5em;
    
}
.four > div > div:nth-child(3){
    margin-top: 0.5em;
    margin-left: 1em;
    font-size: 0.7em;
    font-weight: 300;
}
.five{
    background-color: #f8f6f6;
    padding: 2em;
    display: flex;
    justify-content: space-between;
}
.five > div > div:not(:first-child){
    margin: 0.2em;
    font-size: 0.7em;
    /* line-height: 2em; */
}